import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import style from '../../components/about.module.css'

import logo_tbwa from "../../assets/melbourneAssets/logos/logo_tbwa.svg"

const AboutPage = props => {
  const { data, errors, title } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page

  if (!page) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }
  ///Inline Edit List
  // List of people
  const peopleList=[
    {name:"Paul Reardon", title:"Chief Creative Officer, <br> TBWA\\Melbourne and TBWA\\Adelaide", photo:props.data.headPaulReardon},
    {name:"Kimberlee Wells", title:"CEO, <br> TBWA\\Melbourne and TBWA\\Adelaide", photo:props.data.headKimberleeWells},
    

  ];


  return (
    <Layout site='Adelaide'>
      <SEO title={'About'} />
      <Container>
        <Img
          className={style.headerImage}
          alt={`Office Building`}
          fluid={props.data.headerImage.childImageSharp.fluid}
          objectFit
        />

        <h1 className={`${style.headerCopy} ${style.themeAdelaide}`}>{page.title}</h1>

        <div className={`${style.personListSection} ${style.themeAdelaide}`}>
          <div className={style.aboutIntroText}>
            TBWA\Adelaide is known as the Disruption Company.
          </div>
          <div className={style.personListThreeCol}>

            { peopleList.map((e, i) => {
              return (

                <div className={style.personCardThreeCol} key={`personsCard${i}`}>
                  <Img
                    className={style.personImage}
                    alt={`${e.name} photo`}
                    fluid={e.photo.childImageSharp.fluid}
                    objectFit
                    imgStyle={{objectPosition: 'center top'}}
                  />
                  <div className={style.personCopy}>
                    <p className={style.name}>{e.name}</p>
                    <h5 className={style.title}  dangerouslySetInnerHTML={{ __html: e.title}}>
                      {/*{e.title}*/}

                    </h5>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {/*<ContactButton />*/}

      </Container>
    </Layout>
  )
}

export default AboutPage




export const query = graphql`
  query AdelaideAboutPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title
    }
    headerImage: file(relativePath: { eq: "adelaideAssets/about_header.jpg" }) {
      childImageSharp { 
        fluid(maxWidth: 2560, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headPaulReardon: file(relativePath: { eq: "adelaideAssets/headshots/PaulReardon.png" }) {
      childImageSharp { fluid(maxWidth: 600, quality: 70) { ...GatsbyImageSharpFluid } }
    }
    headKimberleeWells: file(relativePath: { eq: "adelaideAssets/headshots/KimberleeWells.png" }) {
      childImageSharp { fluid(maxWidth: 600, quality: 70) { ...GatsbyImageSharpFluid } }
    }
   headJoeGodsell: file(relativePath: { eq: "adelaideAssets/headshots/JoeGodsell.png" }) {
      childImageSharp { fluid(maxWidth: 600, quality: 70) { ...GatsbyImageSharpFluid } }
    }
  }
`